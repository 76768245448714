import React from 'react';

import { useTranslation } from "react-i18next"




const Yszc = () => {
    const { t } = useTranslation();
    return (
        <div>
            <div class="breadcrumbs">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 offset-lg-3 col-md-12 col-12">
                            <div class="breadcrumbs-content">
                                <h1 class="page-title">{t('news')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section">
                <div class="container">
                <iframe                    
                    src="https://www.tradingview-widget.com/embed-widget/events/?locale=en#%7B%22width%22%3A%22100%25%22%2C%22height%22%3A1000%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22importanceFilter%22%3A%22-1%2C0%2C1%22%2C%22utm_source%22%3A%22www.gffxx-ff6.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22events%22%2C%22page-uri%22%3A%22www.gffxx-ff6.com%2Fcalendar1.html%22%7D" 
                    width="100%"
                    height="800px"
                    title="news"
                    style={{ border: 'none' }}
                ></iframe>
                </div>
            </div>

        </div>
    );
}

export default Yszc;