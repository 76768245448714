import React from 'react';

import { useTranslation } from "react-i18next"




const Yszc = () => {
    const { t } = useTranslation();
    return (
        <div>
            <div class="breadcrumbs">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 offset-lg-3 col-md-12 col-12">
                            <div class="breadcrumbs-content">
                                <h1 class="page-title">{t('yszc')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section">
                <div class="container article">
                    <p>{t('yisizhengce1')}</p>
                    <p>{t('yisizhengce2')}</p>
                    <p>{t('yisizhengce3')}</p>
                    <p>{t('yisizhengce4')}</p>
                    <p>{t('yisizhengce5')}</p>
                    <p>{t('yisizhengce6')}</p>
                    <p>{t('yisizhengce7')}</p>
                    <p>{t('yisizhengce8')}</p>
                    <p>{t('yisizhengce9')}</p>
                    <p>{t('yisizhengce10')}</p>
                    <p>{t('yisizhengce11')}</p>
                    <p>{t('yisizhengce12')}</p>
                    <p>{t('yisizhengce13')}</p>
                </div>
            </div>

        </div>
    );
}

export default Yszc;