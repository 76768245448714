import React from 'react';

import { useTranslation } from "react-i18next"




const Yszc = () => {
    const { t } = useTranslation();
    return (
        <div>
            <div class="breadcrumbs">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 offset-lg-3 col-md-12 col-12">
                            <div class="breadcrumbs-content">
                                <h1 class="page-title">{t('calendar')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section">
                <div class="container">
                <iframe                    
                    src="https://www.tradays.com/en/economic-calendar/widget?mode=1&utm_source=www.gffxx-ff6.com " 
                    width="100%"
                    height="800px"
                    title="calendar"
                    style={{ border: 'none' }}
                ></iframe>
                </div>
            </div>

        </div>
    );
}

export default Yszc;