import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';



const Website = () => {
    const { t, i18n } = useTranslation();
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div>
            <section className="hero-area">
                <img className="hero-shape" src="assets/images/hero/hero-shape.svg" alt="#" />
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12 col-12">
                            <div className="hero-content">
                                <h4 className="wow fadeInUp" data-wow-delay=".2s">{t('banner_title_1')}</h4>
                                <h1 className="wow fadeInUp" data-wow-delay=".4s">{t('banner_title_2')}</h1>
                                <p className="wow fadeInUp" data-wow-delay=".6s">{t('banner_title_3')}</p>
                                <div className="button wow fadeInUp" data-wow-delay=".8s">
                                    <Link to="/platform" className="btn">{t('more')}</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-12">
                            <div className="hero-image">
                                <img className="main-image" src="assets/images/hero/home2-bg.png" alt="#" />
                                <img className="h2-move-1" src="assets/images/hero/h2-bit-l.png" alt="#" />
                                <img className="h2-move-2" src="assets/images/hero/h2-bit-m.png" alt="#" />
                                <img className="h2-move-3" src="assets/images/hero/h2-bit-s.png" alt="#" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="feature section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h3 className="wow zoomIn" data-wow-delay=".2s">{t('section1_title_1')}</h3>
                                <h2 className="wow fadeInUp" data-wow-delay=".4s">{t('section1_title_2')}</h2>
                                <p className="wow fadeInUp" data-wow-delay=".6s">{t('section1_title_3')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".2s">
                            <div className="feature-box">
                                <div className="tumb">
                                    <img src="assets/images/features/feature-icon-1.png" alt="" />
                                </div>
                                <h4 className="text-title">{t('feature1_title')}</h4>
                                <p>{t('feature1_content')}</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".4s">
                            <div className="feature-box">
                                <div className="tumb">
                                    <img src="assets/images/features/feature-icon-2.png" alt="" />
                                </div>
                                <h4 className="text-title">{t('feature2_title')}</h4>
                                <p>{t('feature2_content')}</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".6s">
                            <div className="feature-box">
                                <div className="tumb">
                                    <img src="assets/images/features/feature-icon-3.png" alt="" />
                                </div>
                                <h4 className="text-title">{t('feature3_title')}</h4>
                                <p>{t('feature3_content')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="start-process section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h3 className="wow zoomIn" data-wow-delay=".2s"
                                    style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'zoomIn' }}>{t('section2_title_1')}</h3>
                                <h2 className="wow fadeInUp" data-wow-delay=".4s"
                                    style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>{t('section2_title_2')}</h2>
                                <p className="wow fadeInUp" data-wow-delay=".6s"
                                    style={{ visibility: 'visible', animationDelay: '0.6s', animationName: 'fadeInUp' }}>{t('section2_title_3')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="single-process"> <span className="serial">01</span>
                                <h3>{t('step1_title')}</h3>
                                <p>{t('step1_content')}</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="single-process"> <span className="serial">02</span>
                                <h3>{t('step2_title')}</h3>
                                <p>{t('step2_content')}</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="single-process"> <span className="serial">03</span>
                                <h3>{t('step3_title')}</h3>
                                <p>{t('step3_content')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="team section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h3 className="wow zoomIn" data-wow-delay=".2s"
                                    style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'zoomIn' }}>{t('section3_title_1')}</h3>
                                <h2 className="wow fadeInUp" data-wow-delay=".4s"
                                    style={{ visibility: 'hidden', animationDelay: '0.4s', animationName: 'none' }}>{t('section3_title_2')}</h2>
                                <p className="wow fadeInUp" data-wow-delay=".6s"
                                    style={{ visibility: 'hidden', animationDelay: '0.6s', animationName: 'none' }}>{t('section3_title_3')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-12 wow fadeInUp" data-wow-delay=".3s"
                            style={{ visibility: 'hidden', animationDelay: '0.3s', animationName: 'none' }}>
                            <div className="single-team">
                                <div className="team-image"> <img alt="#" src="assets/images/team/team4.jpg" /> </div>
                                <div className="content">
                                    <h4>Deco Milan <span>{t('investment_guidance')}</span> </h4>
                                    {/* <ul className="social">
                                        <li><a href="javascript:void(0)"><i className="lni lni-facebook-filled"></i></a></li>
                                        <li><a href="javascript:void(0)"><i className="lni lni-instagram"></i></a></li>
                                        <li><a href="javascript:void(0)"><i className="lni lni-twitter-original"></i></a></li>
                                        <li><a href="javascript:void(0)"><i className="lni lni-linkedin-original"></i></a></li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 wow fadeInUp" data-wow-delay=".5s"
                            style={{ visibility: 'hidden', animationDelay: '0.5s', animationName: 'none' }}>
                            <div className="single-team">
                                <div className="team-image"> <img alt="#" src="assets/images/team/team1.jpg" /> </div>
                                <div className="content">
                                    <h4>Liza Marko <span>{t('investment_assistant')}</span> </h4>
                                    {/* <ul className="social">
                                        <li><a href="javascript:void(0)"><i className="lni lni-facebook-filled"></i></a></li>
                                        <li><a href="javascript:void(0)"><i className="lni lni-instagram"></i></a></li>
                                        <li><a href="javascript:void(0)"><i className="lni lni-twitter-original"></i></a></li>
                                        <li><a href="javascript:void(0)"><i className="lni lni-linkedin-original"></i></a></li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 wow fadeInUp" data-wow-delay=".7s"
                            style={{ visibility: 'hidden', animationDelay: '0.7s', animationName: 'none' }}>
                            <div className="single-team">
                                <div className="team-image"> <img alt="#" src="assets/images/team/team2.jpg" /> </div>
                                <div className="content">
                                    <h4>John Smith <span>{t('developer')}</span> </h4>
                                    {/* <ul className="social">
                                        <li><a href="javascript:void(0)"><i className="lni lni-facebook-filled"></i></a></li>
                                        <li><a href="javascript:void(0)"><i className="lni lni-instagram"></i></a></li>
                                        <li><a href="javascript:void(0)"><i className="lni lni-twitter-original"></i></a></li>
                                        <li><a href="javascript:void(0)"><i className="lni lni-linkedin-original"></i></a></li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 wow fadeInUp" data-wow-delay=".9s"
                            style={{ visibility: 'hidden', animationDelay: '0.9s', animationName: 'none' }}>
                            <div className="single-team">
                                <div className="team-image"> <img alt="#" src="assets/images/team/team3.jpg" /> </div>
                                <div className="content">
                                    <h4>Amion Doe <span>{t('developer')}</span> </h4>
                                    {/* <ul className="social">
                                        <li><a href="javascript:void(0)"><i className="lni lni-facebook-filled"></i></a></li>
                                        <li><a href="javascript:void(0)"><i className="lni lni-instagram"></i></a></li>
                                        <li><a href="javascript:void(0)"><i className="lni lni-twitter-original"></i></a></li>
                                        <li><a href="javascript:void(0)"><i className="lni lni-linkedin-original"></i></a></li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="intro-video-area section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="inner-content-head">
                                <div className="inner-content"> <img className="shape1" alt="#" src="assets/images/video/shape1.svg" />
                                    <img className="shape2" alt="#" src="assets/images/video/shape2.svg" />
                                    <div className="section-title"> <span className="wow zoomIn" data-wow-delay=".2s"
                                        style={{ visibility: 'hidden', animationDelay: '0.2s', animationName: 'none' }}>{t('section4_title_1')}</span>
                                        <h2 className="wow fadeInUp" data-wow-delay=".4s"
                                            style={{ visibility: 'hidden', animationDelay: '0.4s', animationName: 'none' }}>{t('section4_title_2')}</h2>
                                        <p className="wow fadeInUp" data-wow-delay=".6s"
                                            style={{ visibility: 'hidden', animationDelay: '0.6s', animationName: 'none' }}>{t('section4_title_3')}</p>
                                    </div>
                                    <img alt="#" src="assets/images/section_img_1.png" />                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="road-map section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h2 className="wow fadeInUp" data-wow-delay=".4s">Road Map</h2>
                                <p className="wow fadeInUp" data-wow-delay=".6s">There are many variations of passages of Lorem
                                    Ipsum available, but the majority have suffered alteration in some form.</p>
                            </div>
                        </div>
                    </div>

                    <div className="top-row">
                        <div className="">
                            <div className="row road-map-slider">
                                <Swiper
                                    spaceBetween={24}
                                    slidesPerView={3}
                                    pagination={{clickable: true}}
                                    modules={[Pagination]}>
                                    <SwiperSlide>
                                        <div className="col-lg-2 col-md-3 col-12 p-0">
                                            <div className="single-map down"> <span className="arrow"></span>
                                                <h3>Apr 10,2018 <span>Development Started</span> </h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="col-lg-2 col-md-3 col-12 p-0">
                                            <div className="single-map"> <span className="arrow"></span>
                                                <h3>May 15,2018 <span>Exchange Bitcontent to Bitcoin</span> </h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="col-lg-2 col-md-3 col-12 p-0">
                                            <div className="single-map down"> <span className="arrow"></span>
                                                <h3>Jan 05,2018 <span>BTCC mode of payment</span> </h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="col-lg-2 col-md-3 col-12 p-0">
                                            <div className="single-map"> <span className="arrow"></span>
                                                <h3>Mar 210,2019 <span>Send-Receive coin Bitconcent & mobile</span> </h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="col-lg-2 col-md-3 col-12 p-0">
                                            <div className="single-map down"> <span className="arrow"></span>
                                                <h3>Feb 25,2020 <span>Coin Marketcap, World Coin Index</span> </h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="col-lg-2 col-md-3 col-12 p-0">
                                            <div className="single-map"> <span className="arrow"></span>
                                                <h3>Dec 22,2020 <span>BTCC mode of payment in Bitcoin</span> </h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="col-lg-2 col-md-3 col-12 p-0">
                                            <div className="single-map down"> <span className="arrow"></span>
                                                <h3>Feb 25,2020 <span>Coin Marketcap, World Coin Index</span> </h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="col-lg-2 col-md-3 col-12 p-0">
                                            <div className="single-map"> <span className="arrow"></span>
                                                <h3>Jan 05,2018 <span>BTCC mode of payment</span> </h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="col-lg-2 col-md-3 col-12 p-0">
                                            <div className="single-map down"> <span className="arrow"></span>
                                                <h3>May 15,2018 <span>Exchange Bitcontent to Bitcoin</span> </h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="col-lg-2 col-md-3 col-12 p-0">
                                            <div className="single-map"> <span className="arrow"></span>
                                                <h3>Dec 22,2020 <span>BTCC mode of payment in Bitcoin</span> </h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="testimonials style2 section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h3 className="wow zoomIn" data-wow-delay=".2s">{t('section5_title_1')}</h3>
                                <h2 className="wow fadeInUp" data-wow-delay=".4s">{t('section5_title_2')}</h2>
                                <p className="wow fadeInUp" data-wow-delay=".6s">{t('section5_title_3')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row testimonial-slider">
                        <Swiper
                            spaceBetween={24}
                            slidesPerView={width > 900 ? 3 : 1}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                        >
                            {[1, 2, 3, 4].map((item, index) => (
                                <SwiperSlide>
                                    <div className="">
                                        <div className="single-testimonial">
                                            <div className="inner-content">
                                                <div className="quote-icon"> <i className="lni lni-quotation"></i> </div>
                                                <div className="text">
                                                    <p>{t(`broker_content_${index + 2}`)}</p>
                                                </div>
                                                <div className="author">
                                                    <h4 className="name">{t(`broker_title_${index + 2}`)}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}


                        </Swiper>

                    </div>
                </div>
            </section>

            <section className="blog-section section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h3 className="wow zoomIn" data-wow-delay=".2s"
                                    style={{ visibility: 'hidden', animationDelay: '0.2s', animationName: 'none' }}>{t('section6_title_1')}</h3>
                                <h2 className="wow fadeInUp" data-wow-delay=".4s"
                                    style={{ visibility: 'hidden', animationDelay: '0.4s', animationName: 'none' }}>{t('section6_title_2')}</h2>
                                <p className="wow fadeInUp" data-wow-delay=".6s"
                                    style={{ visibility: 'hidden', animationDelay: '0.6s', animationName: 'none' }}>{t('section6_title_3')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".4s"
                            style={{ visibility: 'hidden', animationDelay: '0.4s', animationName: 'none' }}>
                            <div className="single-blog-grid">
                                <div className="blog-img"> <a> <img alt="#" src="assets/images/blog/blog-1.png" /> </a>
                                </div>
                                <div className="blog-content">
                                    <h4> <a>{t('trade_title_1')}</a> </h4>
                                    <p>{t('trade_content_1')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".6s"
                            style={{ visibility: 'hidden', animationDelay: '0.6s', animationName: 'none' }}>
                            <div className="single-blog-grid">
                                <div className="blog-img"> <a> <img alt="#" src="assets/images/blog/blog-2.png" /> </a>
                                </div>
                                <div className="blog-content">

                                    <h4> <a>{t('trade_title_2')}</a> </h4>
                                    <p>{t('trade_content_2')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 wow fadeInUp" data-wow-delay=".8s"
                            style={{ visibility: 'hidden', animationDelay: '0.8s', animationName: 'none' }}>
                            <div className="single-blog-grid">
                                <div className="blog-img"> <a> <img alt="#" src="assets/images/blog/blog-3.png" /> </a>
                                </div>
                                <div className="blog-content">
                                    <h4> <a>{t('trade_title_3')}</a> </h4>
                                    <p>{t('trade_content_3')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="faq section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h3 className="wow zoomIn" data-wow-delay=".2s"
                                    style={{ visibility: 'hidden', animationDelay: '0.2s', animationName: 'none' }}>Faq</h3>
                                <h2 className="wow fadeInUp" data-wow-delay=".4s"
                                    style={{ visibility: 'hidden', animationDelay: '0.4s', animationName: 'none' }}>{t('section7_title_1')}</h2>
                                {/* <p className="wow fadeInUp" data-wow-delay=".6s"
                                    style={{ visibility: 'hidden', animationDelay: '0.6s', animationName: 'none' }}>There are many variations of
                                    passages of Lorem
                                    Ipsum available, but the majority have
                                    suffered alteration in some form.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading1">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                                            <span className="title">{t('faq_title_1')}</span><i className="lni lni-plus"></i> </button>
                                    </h2>
                                    <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="heading1"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>{t('faq_content_1')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading2">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                            <span className="title">{t('faq_title_2')}</span><i className="lni lni-plus"></i> </button>
                                    </h2>
                                    <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>{t('faq_content_2')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading3">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                            <span className="title">{t('faq_title_3')}</span><i className="lni lni-plus"></i> </button>
                                    </h2>
                                    <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>{t('faq_content_3')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 xs-margin">
                            <div className="accordion" id="accordionExample2">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading11">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                                            <span className="title">{t('faq_title_4')}</span><i className="lni lni-plus"></i> </button>
                                    </h2>
                                    <div id="collapse11" className="accordion-collapse collapse" aria-labelledby="heading11"
                                        data-bs-parent="#accordionExample2">
                                        <div className="accordion-body">
                                            <p>{t('faq_content_4')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading22">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22"> <span
                                                className="title">{t('faq_title_5')}</span><i className="lni lni-plus"></i> </button>
                                    </h2>
                                    <div id="collapse22" className="accordion-collapse collapse" aria-labelledby="heading22"
                                        data-bs-parent="#accordionExample2">
                                        <div className="accordion-body">
                                            <p>{t('faq_content_5')}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading33">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse33" aria-expanded="false" aria-controls="collapse33"> <span className="title">How
                                                do I benefit from the ICO Token?</span><i className="lni lni-plus"></i> </button>
                                    </h2>
                                    <div id="collapse33" className="accordion-collapse collapse" aria-labelledby="heading33"
                                        data-bs-parent="#accordionExample2">
                                        <div className="accordion-body">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas expedita,
                                                repellendus est nemo cum quibusdam optio, voluptate hic a tempora facere, nihil
                                                non itaque alias similique quas quam odit consequatur.</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default Website;