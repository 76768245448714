import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// 导入翻译文件
import en from './locales/en.json';
import jp from './locales/jp.json';
import kr from './locales/kr.json';
import de from './locales/de.json';
import fr from './locales/fr.json';
import zh_CN from './locales/zh.json';
import zh_hk from './locales/zh_hk.json';

// 配置 i18n
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    jp: { translation: jp },
    kr: { translation: kr },
    de: { translation: de },
    fr: { translation: fr },
    zh_CN: { translation: zh_CN },
    zh_hk: { translation: zh_hk },
  },
  lng: localStorage.getItem('lang')||'zh_CN', // 默认语言
  fallbackLng: localStorage.getItem('lang')||'zh_CN', // 回退语言
  interpolation: {
    escapeValue: false, // React 会自动防止 XSS
  },
});

export default i18n;