import React, { useState, useEffect } from 'react';

import { useTranslation } from "react-i18next"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';



const Platform = () => {
    const { t } = useTranslation();
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    return (
        <div>
            <div class="breadcrumbs">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 offset-lg-3 col-md-12 col-12">
                            <div class="breadcrumbs-content">
                                <h1 class="page-title">{t('platform')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="about section">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-12">
                            <div class="image wow fadeInUp" data-wow-delay=".4s"> <img src="assets/images/about/about-image.png" alt="#" /> </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="content wow fadeInUp" data-wow-delay=".7s">
                                <h4>{t('platform_title_1')}</h4>
                                <h2>{t('platform_title_2')}</h2>
                                <p>{t("platform_title_3")}</p>
                                <div class="list">
                                    <div class="single-list">
                                        <div class="list-icon"> <i class="lni lni-checkmark"></i> </div>
                                        <h4>AOI</h4>
                                        <p>{t('stool_1')}(AOI)</p>
                                    </div>
                                    <div class="single-list">
                                        <div class="list-icon"> <i class="lni lni-checkmark"></i> </div>
                                        <h4>AC</h4>
                                        <p>{t('stool_2')}</p>
                                    </div>
                                    <div class="single-list">
                                        <div class="list-icon"> <i class="lni lni-checkmark"></i> </div>
                                        <h4>ADC</h4>
                                        <p>{t('stool_3')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="start-process dark section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h3 className="wow zoomIn" data-wow-delay=".2s"
                                    style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'zoomIn' }}>{t('section2_title_1')}</h3>
                                <h2 className="wow fadeInUp" data-wow-delay=".4s"
                                    style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>{t('section2_title_2')}</h2>
                                <p className="wow fadeInUp" data-wow-delay=".6s"
                                    style={{ visibility: 'visible', animationDelay: '0.6s', animationName: 'fadeInUp' }}>{t('section2_title_3')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="single-process"> <span className="serial">01</span>
                                <h3>{t('step1_title')}</h3>
                                <p>{t('step1_content')}</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="single-process"> <span className="serial">02</span>
                                <h3>{t('step2_title')}</h3>
                                <p>{t('step2_content')}</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="single-process"> <span className="serial">03</span>
                                <h3>{t('step3_title')}</h3>
                                <p>{t('step3_content')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="testimonials style2 section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h3 className="wow zoomIn" data-wow-delay=".2s">{t('section5_title_1')}</h3>
                                <h2 className="wow fadeInUp" data-wow-delay=".4s">{t('section5_title_2')}</h2>
                                <p className="wow fadeInUp" data-wow-delay=".6s">{t('section5_title_3')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row testimonial-slider">
                        <Swiper
                            spaceBetween={24}
                            slidesPerView={width>900?3:1}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                        >
                            {[1, 2, 3, 4].map((item, index) => (
                                <SwiperSlide>
                                    <div className="">
                                        <div className="single-testimonial">
                                            <div className="inner-content">
                                                <div className="quote-icon"> <i className="lni lni-quotation"></i> </div>
                                                <div className="text">
                                                    <p>{t(`broker_content_${index + 2}`)}</p>
                                                </div>
                                                <div className="author">
                                                    <h4 className="name">{t(`broker_title_${index + 2}`)}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Platform;