import React from 'react';
import { useTranslation } from "react-i18next"




const Mzsm = () => {
    const { t } = useTranslation();
    return (
        <div>
            <div class="breadcrumbs">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 offset-lg-3 col-md-12 col-12">
                            <div class="breadcrumbs-content">
                                <h1 class="page-title">{t('mzsm')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section">
                <div class="container article">
                    <p>{t('mzsm1')}</p>
                    <p>{t('mzsm2')}</p>
                    <p>{t('mzsm3')}</p>
                    <p>{t('mzsm4')}</p>
                    <p>{t('mzsm5')}</p>
                    <p>{t('mzsm6')}</p>
                    <p>{t('mzsm7')}</p>
                    <p>{t('mzsm8')}</p>
                    <p>{t('mzsm9')}</p>
                    <p>{t('mzsm10')}</p>
                    <p>{t('mzsm11')}</p>              
                </div>
            </div>

        </div>
    );
}

export default Mzsm;