import React, { useRef, useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { Link } from 'react-router-dom';
import useStore from '../store/index.js'
import { useTranslation } from "react-i18next"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';



const Fxpl = () => {
    const { t, i18n } = useTranslation();
    return (
        <div>
            <div class="breadcrumbs">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 offset-lg-3 col-md-12 col-12">
                            <div class="breadcrumbs-content">
                                <h1 class="page-title">{t('fxpl')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section">
                <div class="container article">
                    <p>{t('fenxianpilou2')}</p>
                    <p>{t('fenxianpilou3')}</p>
                    <p>{t('fenxianpilou4')}</p>
                    <p>{t('fenxianpilou5')}</p>
                    <p>{t('fenxianpilou6')}</p>
                    <p>{t('fenxianpilou7')}</p>
                    <p>{t('fenxianpilou8')}</p>
                    <p>{t('fenxianpilou9')}</p>
                    <p>{t('fenxianpilou10')}</p>
                    <p>{t('fenxianpilou11')}</p>
                    <p>{t('fenxianpilou12')}</p>
                    <p>{t('fenxianpilou13')}</p>
                    <p>{t('fenxianpilou14')}</p>
                </div>
            </div>

        </div>
    );
}

export default Fxpl;