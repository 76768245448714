import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next"

import Product from './view/product';
import Website from './view/website';
import Platform from './view/platform';
import Contact from './view/contact';
import Fxpl from './view/fxpl';
import Yszc from './view/yszc';
import Mzsm from './view/mzsm';
import Calendar from './view/calendar';
import News from './view/news';
import ScrollTop from './components/scrollTop'





const App = () => {
  const { t, i18n } = useTranslation();

  const handleChangeLang = lang => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang)
  }

  return (
    <div>
      <ScrollTop />
      <div className="preloader">
        <div className="preloader-inner">
          <div className="preloader-icon">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>

      <header className="header navbar-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="nav-inner">

                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand" href="index.html">
                    <img src="assets/images/logo/white-logo.png" alt="Logo" />
                  </a>
                  <button className="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                    <ul id="nav" className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <a href="index.html" aria-label="Toggle navigation">{t('home')}</a>
                      </li>
                      <li className="nav-item"><Link to="/platform">{t('platform')}</Link></li>
                      <li className="nav-item">
                        <a className="dd-menu collapsed" href="javascript:void(0)" data-bs-toggle="collapse"
                          data-bs-target="#submenu-1-2" aria-controls="navbarSupportedContent" aria-expanded="false"
                          aria-label="Toggle navigation">{t('smzc')}</a>
                        <ul className="sub-menu collapse" id="submenu-1-2">
                          <li className="nav-item">
                            <Link to="/fxpl">{t('fxpl')}</Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/yszc">{t('yszc')}</Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/mzsm">{t('mzsm')}</Link>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item">
                        <a className="dd-menu collapsed" href="javascript:void(0)" data-bs-toggle="collapse"
                          data-bs-target="#submenu-1-3" aria-controls="navbarSupportedContent" aria-expanded="false"
                          aria-label="Toggle navigation">{t('market_information')}</a>
                        <ul className="sub-menu collapse" id="submenu-1-3">
                          <li className="nav-item">
                            <Link to="/calendar">{t('calendar')}</Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/news">{t('news')}</Link>
                          </li>

                        </ul>
                      </li>
                      {/* <li className="nav-item">
                        <Link to="/contact">{t('contact')}</Link>           
                      </li> */}
                      <li className="nav-item">
                        <a className="dd-menu collapsed" href="javascript:void(0)" data-bs-toggle="collapse"
                          data-bs-target="#submenu-1-1" aria-controls="navbarSupportedContent" aria-expanded="false"
                          aria-label="Toggle navigation">{t('language')}</a>
                        <ul className="sub-menu collapse" id="submenu-1-1">
                          <li className="nav-item"><a href="#" onClick={() => { handleChangeLang('zh_CN') }}>中文简体</a></li>
                          <li className="nav-item"><a href="#" onClick={() => { handleChangeLang('en') }}>English</a></li>
                          <li className="nav-item"><a href="#" onClick={() => { handleChangeLang('jp') }}>日本語</a></li>
                          <li className="nav-item"><a href="#" onClick={() => { handleChangeLang('kr') }}>한국인</a></li>
                          <li className="nav-item"><a href="#" onClick={() => { handleChangeLang('de') }}>Deutsch</a></li>
                          <li className="nav-item"><a href="#" onClick={() => { handleChangeLang('fr') }}>Français</a></li>
                          {/* <li className="nav-item"><a href="signup.html">Sign Up</a></li>
                          <li className="nav-item"><a href="reset-password.html">Reset Password</a></li>
                          <li className="nav-item"><a href="mail-success.html">Mail Success</a></li>
                          <li className="nav-item"><a href="404.html">404 Error</a></li> */}
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="button">
                    <Link to="http://superapp.superai7.cc/#/" target="_blank" className="btn">{t('start')}</Link>
                  </div>
                </nav>

              </div>
            </div>
          </div>
        </div>
      </header>
      <Routes>
        <Route path="/" element={<Website />} />
        <Route path="/platform" element={<Platform />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/fxpl" element={<Fxpl />} />
        <Route path="/yszc" element={<Yszc />} />
        <Route path="/mzsm" element={<Mzsm />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/news" element={<News />} />

        <Route path="/product/:productName" element={<Product />} />
      </Routes>

      <footer className="footer section">
        <div className="footer-top">
          <div className="container">
            <div className="inner-content">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">

                  <div className="single-footer f-about">
                    <div className="logo">
                      <a href="index.html">
                        <img src="assets/images/logo/white-logo.png" alt="#" />
                      </a>
                    </div>
                    <p>{t('footer_title')}</p>
                    <h4 className="social-title">{t('contact_method')}:</h4>
                    <p>email@email.com</p>
                  </div>

                </div>
                <div className="col-lg-2 col-md-6 col-12">

                  <div className="single-footer f-link">
                    <h3>{t('smzc')}</h3>
                    <ul>
                      <li><Link to="/fxpl">{t('fxpl')}</Link> </li>
                      <li><Link to="/yszc">{t('yszc')}</Link></li>
                      <li><Link to="/mzsm">{t('mzsm')}</Link></li>

                    </ul>
                  </div>

                </div>
                <div className="col-lg-2 col-md-6 col-12">

                  <div className="single-footer f-link">
                    <h3>{t('market_information')}</h3>
                    <ul>
                      <li><Link to="/calendar">{t('calendar')}</Link> </li>
                      <li><Link to="/news">{t('news')}</Link> </li>
                    </ul>
                  </div>

                </div>
                <div className="col-lg-4 col-md-6 col-12">

                  <div className="single-footer newsletter">
                    <h3>{t('download')}</h3>
                    <div>
                      <Link to="https://apps.apple.com/us/app/stockease/id6738416500?l=zh-Hans-CN" target="_blank">
                        <img src="assets/images/logo/down3.png" alt="#" />
                      </Link>
                    </div>

                    <div style={{ marginTop: '20px' }}>
                      <Link to="https://play.google.com/store/apps/details?id=com.vinqim.vinqim" target="_blank">
                        <img src="assets/images/logo/down4.png" alt="#" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-area">
          <div className="container">
            <div className="inner-content">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <p className="copyright-text">© 2023 sa7 - All Rights Reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </footer>



      <a href="#" className="scroll-top">
        <i className="lni lni-chevron-up"></i>
      </a>

    </div>
  );
};

export default App;

