import React, { useRef, useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { Link } from 'react-router-dom';
import useStore from '../store/index.js'
import { useTranslation } from "react-i18next"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';



const Contact = () => {
    // const { productName } = useParams()

    const { t, i18n } = useTranslation();
    // const { title, url, full, account, password } = useStore()[productName];



    return (
        <div>
            <div class="breadcrumbs">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 offset-lg-3 col-md-12 col-12">
                            <div class="breadcrumbs-content">
                                <h1 class="page-title">{t('contact')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="contact-us section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-12">
                            <div class="contact-widget-wrapper">
                                <div class="main-title">
                                    <h2>Contact Us</h2>
                                    <p>There are many variations of passages of Lorem
                                        Ipsum available, but the majority have suffered.</p>
                                </div>
                                <div class="contact-widget-block">
                                    <h3 class="title">Call us</h3>
                                    <p>+14-394-409-591</p>
                                </div>
                                <div class="contact-widget-block">
                                    <h3 class="title">Email us</h3>
                                    <p><a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="91f8fff7fed1fcf0f8fdbff2fefc">[email&#160;protected]</a></p>
                                    <p><a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="65161015150a17112508040c094b060a08">[email&#160;protected]</a></p>
                                </div>
                                <div class="contact-widget-block">
                                    <h3 class="title">Our Address</h3>
                                    <p>34 Madison Street,</p>
                                    <p>NY, USA 10005</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="contact-form">
                                <h3 class="form-title">Leave a message here</h3>
                                <form class="form" method="post" action="assets/mail/mail.php">

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Contact;